<template>
  <div class="mobile-footer">
    <img src="../assets/imgs/mobile/logo@2x.png" class="logo"/>
    <div class="footer-info">
      <div class="common-detail">
        <p @click="toPage('/')">首页</p>
        <p @click="toPage('/LocalizationPlan')">国产化方案</p>
        <!-- <p>物理印章</p> -->
        <p @click="toPage('http://seal.lngsyz.com', true)">电子印章</p>
        <p @click="toPage('https://kq.lngsyz.com/login',true)">刻企服务</p>
        <!-- <p>个人签名/身份认证</p> -->
        <!-- <p>产品认证</p> -->
        <p @click="toPage('/Product')">印材设备</p>
<!--        <p @click="toPage('https://kq.lngsyz.com/collect/sealInfo/toMakeSeal', true)">申请有礼</p>-->
        <p @click="toPage('/ResourcesDownload')">资源下载</p>
        <p @click="toPage('/About')">关于广烁</p>
        <p @click="toPage('/ContactUs')">联系我们</p>
      </div>
      <div>
        <div class="code-info">
          <img src="../assets/imgs/code@2x.png" class="gs-code">
          <div>关注广烁微信</div>
        </div>
        <div class="phone-content">
          <img src="../assets/imgs/mobile/icon_phone@2x.png" class="phone"/>
          <p>0411-82951884</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileFooter',
  methods: {
    toPage(url, isExternal) {
      console.log(url, this.$route.path);
      if (isExternal) {
        window.open(url, '_black');
      }
      if (url && url !== this.$route.path && !isExternal) {
        this.$router.push(url);
      }
    },
  },
};
</script>

<style lang="less" scoped>
  .mobile-footer {
    background-color: #1C1F37;
    font-family: PingFangSC-Semibold, PingFang SC;
    padding: 30px;
    .logo{
      width: 188px;
      height: 24px;
      display: block;
      margin: 0 auto 36px;
    }
    p {
      margin: 0;
      cursor: pointer;
    }
    .footer-info {
      display: flex;
      justify-content: space-between;
    }
    .common-detail {
      color: #fff;
      p{
        margin-bottom: 8px;
      }
    }
    .focus-us {
      margin-right: 60px;
      color: #fff;
      div {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 16px;
      }
      p {
        margin-top: 10px;
      }
      p:first-of-type {
        margin-top: 0;
      }
    }
    .code-info {
      display: flex;
      justify-content: flex-end;
      .gs-code {
        height: 108px;
        width: 108px;
      }
      div {
        width: 18px;
        margin: 3px 0 0 8px;
        font-size: 12px;
        color: #FFFFFF;
      }
    }
    .phone-content{
      display: flex;
      align-items: center;
      margin-top: 16px;
      img{
        width: 25px;
        height: 37px;
      }
      p{
        font-size: 14px;
        color: #FFFFFF;
        margin-left: 10px;
      }
    }
  }
</style>
